import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import {
  groupFeedTopicsAddTopicClickIntent,
  groupFeedTopicsTopicActions,
} from '@wix/bi-logger-groups/v2';

import type { ITopic } from 'api/topics/types';
import {
  filterTopics,
  selectFeedPermissionsByGroup,
  selectLastCreatedTopic,
  selectTopicsStatus,
} from 'store/selectors';

import { useDidUpdate } from 'common/hooks';

import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Checkbox } from 'wui/Checkbox';
import { TextField } from 'wui/TextField';
import { Skeleton } from 'wui/Skeleton';
import { EmptyState } from 'wui/EmptyState';
import { InputDialog } from 'wui/InputDialog';
import { DialogContent } from 'wui/DialogContent';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogActions } from 'wui/DialogActions';
import { List } from 'wui/List';
import { ListItem } from 'wui/ListItem';
import { ListItemButton } from 'wui/ListItemButton';
import { ListItemText } from 'wui/ListItemText';
import { ListItemIcon } from 'wui/ListItemIcon';
import { ListItemAction } from 'wui/ListItemAction';
import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';

import { Topic } from '../../Topic';
import { CreateTopicDialog } from './CreateTopicDialog';

import classes from './TopicsDialog.scss';

interface IProps extends React.ComponentProps<typeof InputDialog> {
  groupId: string;
  value: ITopic[];
  isNewPost?: boolean;

  onChange(topics: ITopic[]): void;
}

export function TopicsDialog(props: IProps) {
  const { groupId, value, onChange, isNewPost, ...rest } = props;

  const bi = useBi();
  const { t } = useTranslation();

  const [query, setQuery] = React.useState('');
  const [createOpen, setCreateOpen] = React.useState(false);

  const topics = useSelector(filterTopics(query));
  const statuses = useSelector(selectTopicsStatus);
  const lastCreated = useSelector(selectLastCreatedTopic);
  const permissions = useSelector(selectFeedPermissionsByGroup(groupId));

  useDidUpdate(() => {
    if (lastCreated) {
      handleSelectTopic(lastCreated);
    }
  }, [lastCreated?.id]);

  return (
    <>
      <InputDialog {...rest}>
        <DialogTitle
          title={t('groups-web.discussion.topics.dialog.title', {
            count: value.length,
          })}
        />

        <Box direction="vertical" padding="SP0 SP5">
          <TextField
            value={query}
            withClearButton
            onChange={handleQueryChange}
            onClear={handleQueryClear}
            maxLength={40}
            prefix={<SearchIcon />}
            placeholder={t(
              'groups-web.discussion.topics.modal.search.placeholder',
            )}
          />
        </Box>

        <DialogContent disableSideGutters>
          <Show if={statuses.fetch.pending}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Skeleton variant="rect" width="100px" height="20px" />
                </ListItemIcon>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Skeleton variant="rect" width="110px" height="20px" />
                </ListItemIcon>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Skeleton variant="rect" width="90px" height="20px" />
                </ListItemIcon>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Skeleton variant="rect" width="120px" height="20px" />
                </ListItemIcon>
              </ListItem>
            </List>
          </Show>

          <Hide if={statuses.fetch.pending}>
            <Hide if={!!topics.length}>
              <EmptyState
                variant="section"
                subtitle={
                  query
                    ? t(
                        'groups-web.discussion.topics.modal.status-label.filters-empty',
                      )
                    : t('groups-web.discussion.topics.dialog.empty.label')
                }
              />
            </Hide>

            <Show if={!!topics.length}>
              <List disablePadding>
                {topics.map((topic) => (
                  <ListItem disablePadding key={topic.id}>
                    <ListItemButton onClick={handleTopicToggle(topic)}>
                      <ListItemIcon className={classes.topic}>
                        <Topic
                          key={topic.id}
                          topic={topic}
                          active={isSelected(topic.id)}
                          ellipsis
                        />
                      </ListItemIcon>
                      <ListItemText />
                      <ListItemAction>
                        <Checkbox checked={isSelected(topic.id)} />
                      </ListItemAction>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Show>
          </Hide>
        </DialogContent>

        <Show if={permissions.canCreateTopic}>
          <DialogActions>
            <Button
              fullWidth
              outlined
              variant="basic"
              onClick={openCreateDialog}
            >
              + {t('groups-web.discussion.create-topic.dialog.open-button')}
            </Button>
          </DialogActions>
        </Show>
      </InputDialog>

      <CreateTopicDialog
        groupId={groupId}
        isOpen={createOpen}
        onClose={handleCreateDialogClose}
      />
    </>
  );

  function handleTopicToggle(topic: ITopic) {
    return function () {
      if (isSelected(topic.id)) {
        handleRemoveTopic(topic);
      } else {
        handleSelectTopic(topic);
      }
    };
  }

  function openCreateDialog() {
    setCreateOpen(true);
  }

  function handleCreateDialogClose() {
    setCreateOpen(false);
  }

  function handleQueryClear() {
    setQuery('');
  }

  function handleQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
  }

  function isSelected(id?: string) {
    return value.some((topic) => topic.id === id);
  }

  function handleRemoveTopic(topic: ITopic) {
    onChange(value.filter(({ id }) => id !== topic.id));

    bi.report(
      groupFeedTopicsTopicActions({
        groupId,
        action: 'delete',
        origin: !isNewPost ? 'post_creation' : 'post_to_the_topic',
        topicName: topic.displayName,
        topicId: topic.id,
      }),
    );
  }

  function handleSelectTopic(topic: ITopic) {
    onChange([...value, topic]);

    if (!isNewPost) {
      bi.report(
        groupFeedTopicsTopicActions({
          groupId,
          action: 'edit',
          origin: 'post_to_the_topic',
          topicName: topic.displayName,
          topicId: topic.id,
        }),
      );
    } else {
      bi.report(
        groupFeedTopicsAddTopicClickIntent({
          groupId,
          origin: 'topics_list_in_post',
        }),
      );
    }
  }
}

TopicsDialog.displayName = 'TopicsDialog';
