import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import type { IFeedItem } from 'api/feed/types';
import { selectCurrentUser } from 'store/selectors';
import { useController } from 'common/context/controller';

import * as feed from 'settings/feed';

import { Hide } from 'wui/Hide';
import { Show } from 'wui/Show';
import { Fade } from 'wui/Fade';
import { Wire } from 'wui/Wire';
import { Stack } from 'wui/Stack';
import { TextButton } from 'wui/TextButton';
import { Typography } from 'wui/Typography';
import { CardContent } from 'wui/CardContent';

import { Reaction } from './Reaction';
import { ReactionsPicker } from './ReactionsPicker';

import {
  selectHasReactions,
  selectReactionsMap,
} from '../ReactedMembers/selectors';

import feedItemClasses from '../FeedItem.scss';
import { selectReactionCodes } from './selectors';

interface IProps {
  item: IFeedItem;

  toggleComments?(): void;
  onReact?(code?: string): void;
}

export function Reactions(props: IProps) {
  const { item, onReact, toggleComments } = props;

  const { t } = useTranslation();
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { application$, feed$ } = useController();

  const feedItemId = item.feedItemId as string;
  const canReact = !!item.permissions?.canReact;
  const groupId = item.applicationContext?.contextId as string;

  const currentUser = useSelector(selectCurrentUser);
  const reactions = useSelector(selectReactionsMap(feedItemId));
  const hasReactions = useSelector(selectHasReactions(feedItemId));

  const [primary, secondary] = useSelector(
    selectReactionCodes(feedItemId, ['👍']),
  );

  const didReact = useCallback(
    (code: string) => {
      return reactions[code]?.some(
        (reaction) => currentUser.siteMemberId === reaction.user.siteMemberId,
      );
    },
    [reactions],
  );

  return (
    <CardContent
      data-hook="reactions"
      direction="horizontal"
      align="space-between"
      verticalAlign="middle"
    >
      <div>
        <Show if={item.permissions?.canViewFullPost}>
          <Wire
            cssVarName="showReactions"
            legacyFallback={settings.get(feed.settings.showReactions)}
          >
            <Stack wrap as={TransitionGroup} gap={isMobile ? 'SP2' : 'SP3'}>
              {primary.map((code) => (
                <Reaction
                  key={code}
                  code={code}
                  feedItemId={feedItemId}
                  reacted={didReact(code)}
                  onClick={createClickHandler(code)}
                />
              ))}

              {secondary.map((code) => (
                <Fade key={code}>
                  <Reaction
                    code={code}
                    feedItemId={feedItemId}
                    reacted={didReact(code)}
                    onClick={createClickHandler(code)}
                  />
                </Fade>
              ))}

              <ReactionsPicker onSelect={handleReactionToggle} />
            </Stack>
          </Wire>
        </Show>
      </div>

      <Hide if={hasReactions}>
        <Typography variant="p2-14" className={feedItemClasses.totalComments}>
          <TextButton onClick={toggleComments} variant="secondary">
            {t('groups-web.discussion.feed.total-comments_icu', {
              count: item.comments?.total || item.latestComments?.total || 0,
            })}
          </TextButton>
        </Typography>
      </Hide>
    </CardContent>
  );

  function createClickHandler(code: string) {
    return function () {
      handleReactionToggle(code);
    };
  }

  function handleReactionToggle(code: string) {
    if (didReact(code)) {
      return feed$.unreact(groupId, feedItemId, code);
    }

    if (canReact) {
      feed$.react(groupId, feedItemId, code);
      onReact?.(code);
    } else {
      application$.showDialog({ dialog: 'joinGroup', params: { groupId } });
    }
  }
}

Reactions.displayName = 'LegacyReactions';
