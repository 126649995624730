import React from 'react';
import _ from 'lodash';
import cls from 'classnames';

import { Box } from '../Box';
import { Avatar, type IAvatarProps } from '../Avatar';
import { type TPAComponentProps } from '../types';

import styles from './AvatarGroup.scss';

interface IProps
  extends Pick<IAvatarProps, 'size' | 'lettersLimit'>,
    TPAComponentProps {
  maxAmount?: number;
  children?: React.ReactNode;

  items: Omit<IAvatarProps, 'size' | 'lettersLimit'>[];
}

export function AvatarGroup(props: IProps) {
  const { children, items, maxAmount, size, lettersLimit, className, ...rest } =
    props;

  return (
    <Box gap="SP2" className={cls(styles.root, className)} {...rest}>
      <Box gap="SP0" className={styles.list}>
        {_.take(items, maxAmount).map((item, index) => (
          <Avatar
            key={index}
            {...item}
            size={size}
            lettersLimit={lettersLimit}
            className={styles.item}
          />
        ))}
      </Box>
      {children}
    </Box>
  );
}

AvatarGroup.displayName = 'wui/AvatarGroup';
