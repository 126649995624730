import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@wix/yoshi-flow-editor';

import { selectGroupName, selectGroupSlugById } from 'store/selectors';

import { Link } from '../../Link';

import { IActivityDescriptionProps } from './types';

export function GroupPost(props: IActivityDescriptionProps) {
  const { item, full } = props;

  const groupId = item.applicationContext?.contextId as string;

  const slug = useSelector(selectGroupSlugById(groupId));
  const groupName = useSelector(selectGroupName(groupId));

  if (!full) {
    return null;
  }

  return (
    <Trans
      i18nKey="groups-web.activity-post.with-group-name"
      values={{ groupName }}
      components={[
        <span key={0} />,
        <Link key={1} state="group" params={{ slug }} />,
      ]}
    />
  );
}

GroupPost.displayName = 'GroupPost';
